var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"table-page-search-wrapper",staticStyle:{"margin-bottom":"10px","padding":"20px"}},[_c('AdvanceTable',{attrs:{"columns":_vm.columns,"data-source":_vm.list,"title":_vm.tableTitle,"loading":_vm.loading,"rowKey":"id","scroll":{ x: 1300 },"size":"middle","tableKey":"view-text-table","format-conditions":true,"pagination":{
		  current: _vm.queryParam.pageNum,
		  pageSize: _vm.queryParam.pageSize,
		  total: _vm.total,
		  showSizeChanger: true,
		  showLessItems: true,
		  showQuickJumper: true,
		  showTotal: function (total, range) { return ("第 " + (range[0]) + "-" + (range[1]) + " 条，总计 " + total + " 条"); },
		  onChange: _vm.changeSize,
		  onShowSizeChange: _vm.onShowSizeChange,
		}},on:{"refresh":_vm.scorelist},scopedSlots:_vm._u([{key:"type",fn:function(ref){
		var text = ref.text;
return _c('span',{},[_c('a-tag',[_vm._v(_vm._s(text == 2 ? '登录' : '注册'))])],1)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }